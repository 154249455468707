<template>
  <h1 class="download-wrap">
    下载
  </h1>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>
.download-wrap {
  box-sizing: border-box;
  // padding-bottom: 230px;
  padding-top: 90px;
  height: 800px;
  text-align: center;
}
</style>