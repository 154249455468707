import Vue from "vue";
import VueRouter from "vue-router";
import Retail from "@/views/Retail/index.vue"; //零售版
import FAB from "@/views/FoodAndBeverage/index.vue"; //餐饮版
import Download from "@/views/Download/index.vue"; //下载

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "retail",
    component: Retail,
  },
  {
    path: "/fb",
    name: "fb",
    component: FAB,
  },
  {
    path: "/download",
    name: "download",
    component: Download,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
